<template>

    <div class="interactive-graphic-editor-component">

        <div v-html="getSvgStyle()"></div>

        <div class="interactive-graphic-editor-component-svg" v-html="svg" ref="svg" @click="clickSvg"></div>

        <div class="interactive-graphic-editor-component-content">

            <ckeditor :editor="editor" :config="editorConfig"
                      v-model="config[selectedElementIdentifier]" v-if="selectedElementIdentifier" @blur="onChangeConfig()"></ckeditor>

        </div>

    </div>

</template>

<script>
    import draggable from 'vuedraggable';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    export default {
        data() {
            return {
                selectedElement: null,
                selectedElementIdentifier: null,
                editor: ClassicEditor,
                editorConfig: {
                    basicEntities: false,
                    toolbar: {
                        items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'link',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'undo',
                            'redo',
                        ]
                    }
                },
            };
        },
        components: {
            draggable,
        },
        props: {
            svg: {
                type: String,
                required: false,
            },
            selector: {
                type: String,
                required: false,
            },
            config: {
                type: Object,
                required: false,
            },
        },
        computed: {
        },
        methods: {
            clickSvg (event) {

                if(!event.target.matches(this.selector)) {
                    return;
                }

                this.clickSvgElement(event.target);

            },
            clickSvgElement (target) {
                let svgClone = this.$refs.svg.querySelector('svg').cloneNode(false);

                svgClone.append(target.cloneNode(true));

                this.selectedElement = target;
                this.selectedElementIdentifier = svgClone.innerHTML;

                this.$refs.svg.querySelectorAll('svg '+this.selector).forEach((e) => {
                    e.classList.remove('active');
                });

                this.selectedElement.classList.add('active');
            },
            getSvgStyle () {
                let style = '';

                if(!this.selector) {
                    return style;
                }

                style += '.interactive-graphic-editor-component svg *' + ' ' +
                    '{' +
                        'pointer-events: none;' +
                    '}';

                style += '.interactive-graphic-editor-component svg ' + this.selector + ' ' +
                    '{' +
                        'opacity: .25;' +
                        'cursor: pointer;' +
                        'pointer-events: auto;' +
                        'transition: all .25s;' +
                    '}';

                style += '.interactive-graphic-editor-component svg ' + this.selector + ':hover ' +
                    '{' +
                        'opacity: 1;' +
                    '}';

                style += '.interactive-graphic-editor-component svg ' + this.selector + '.active ' +
                    '{' +
                        'opacity: 1;' +
                    '}';

                return '<style>'+style+'</style>';
            },
            onChangeConfig () {
                this.$emit('onChangeConfig', this.config);
            },
        },
    }
</script>